import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC9yxBrCC9dweJDxYi9teaSSQcd5s1Z7uQ",
    authDomain: "oloop-studio-286216.firebaseapp.com",
    // databaseURL: "https://oloop-studio-286216.firebaseio.com",
    projectId: "oloop-studio-286216",
    storageBucket: "oloop-studio-286216.appspot.com",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// const storage = getStorage(app);
export const functions = getFunctions(app, "asia-southeast2");
export const auth = getAuth(app);