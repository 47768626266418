import { useEffect } from 'react';
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from 'firebase/functions';
import { connectAuthEmulator } from 'firebase/auth';
import { db, functions, auth } from './utils/firebase';
import Form from './components/Form';

// const firebaseConfig = {
//     apiKey: "AIzaSyC9yxBrCC9dweJDxYi9teaSSQcd5s1Z7uQ",
//     authDomain: "oloop-studio-286216.firebaseapp.com",
//     // databaseURL: "https://oloop-studio-286216.firebaseio.com",
//     projectId: "oloop-studio-286216",
//     storageBucket: "oloop-studio-286216.appspot.com",
// };
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
// // const storage = getStorage(app);
// const functions = getFunctions(app, "asia-southeast2");
// const auth = getAuth(app);

function App() {

    useEffect(() => {
        if (window.location.hostname === "localhost") {
            connectFirestoreEmulator(db, "127.0.0.1", 8080);
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
            connectAuthEmulator(auth, "http://127.0.0.1:9099");
        }
    }, [])

    return (
        <div className="h-full pb-20 md:pb-32 5xl:pb-0 5xl:h-screen">
            <div className="absolute top-0 left-1/2 pt-10" style={{ transform: "translate(-50%, 0)" }}>
                <img className="w-full md:w-2/5 mx-auto" src="/logo.png" alt="" />
            </div>
            <div className="h-full flex justify-center items-center pt-28">
                <Form />
            </div>
        </div>
    );
}

export default App;
