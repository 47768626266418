import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Controller } from 'react-hook-form';

const animatedComponents = makeAnimated();

const options = [
    { value: "Gather Industry Knowledge", label: "Gather Industry Knowledge" },
    { value: "Network with other public sector officials", label: "Network with other public sector officials" },
    { value: "Source New Products and Solutions", label: "Source New Products and Solutions" },
    { value: "Peer to Peer Meetings", label: "Peer to Peer Meetings" },
    { value: "Looking for new job opportunities", label: "Looking for new job opportunities" },
    { value: "Industry Knowledge", label: "Industry Knowledge" }
]

const CustomSelect = ({ control }) => {
    return (
        <>
            <Controller
                control={control}
                rules={{ required: "Objectives is required" }}
                defaultValue={[]}
                name="objectives"
                render={({ field: { onChange, value, ref }}) => (
                    <Select
                        placeholder="Select Objectives"
                        classNames="bg-transparent"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        inputRef={ref}
                        value={options.filter(c => value.includes(c.value))}
                        onChange={val => onChange(val.map(c => c.value))}
                        options={options}
                        isMulti
                    />
                )}
            />
        </>
    )
}

export default CustomSelect;