import { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
    Input,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Select,
    Option,
    Checkbox
} from "@material-tailwind/react";
import { useForm, Controller } from "react-hook-form";
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'

import CustomSelect from "./CustomSelect";
import { defaultValues, jobFunctions, jobLevels, privateSector, publicSector, jobFunctionsIt } from "../data/staticData";
import { db, functions } from '../utils/firebase';
import { httpsCallable } from "firebase/functions";
const Form = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState: {
            errors,
            isSubmitting,
        } } = useForm({
            defaultValues,
            reValidateMode: "onBlur",
            mode: "onBlur"
        });

    const watchJobFunction = watch("jobFunction", "");
    const watchJobLevel = watch("jobLevel", "");
    const watchSector = watch("sector", "");
    // const watchNonDelegates = watch("nonDelegates", "");
    const watchPrivateSectorIndustry = watch("privateSectorIndustry", "");
    const watchPublicSectorIndustry = watch("publicSectorIndustry", "");

    const [successAddAttendee, setSuccessAddAttendee] = useState(false);
    // const [showCategoryType, setShowCategoryType] = useState(false);

    const handleOpen = () => {
        if (successAddAttendee) {
            reset();
        }
        setSuccessAddAttendee(!successAddAttendee)
    };


    const verifyEmail = async (email) => {
        const verifyEmailFunction = httpsCallable(functions, 'verifyEmail');
        const result = await verifyEmailFunction({ email });
        return result.data.exists;
    };

    const onSubmit = async (data) => {
        const {
            contact,
            nonDelegates,
            batchCategory,
            ...restData
        } = data;

        try {

            const addAttendeeFunction = httpsCallable(functions, 'addAttendee');
            const result = await addAttendeeFunction({
                ...restData,
                onSiteInput: true,
                accepted: true,
                nonDelegates: false,
                batchCategory: "Delegate",
                contact,
                timestamp: new Date(),
                printed: false
            });
            const newAttendeeSnapshot = result.data;
            if (!newAttendeeSnapshot) {
                throw new Error("Failed to add new attendee")
            };
            setSuccessAddAttendee(true)
            console.log("Document written with ID: ", newAttendeeSnapshot.id);
            return newAttendeeSnapshot.id;
        } catch (error) {
            console.error(error)
        }
    };

    // useEffect(() => {
    //     setShowCategoryType(watchNonDelegates === "true");
    // }, [watchNonDelegates])

    useEffect(() => {
        if (watchSector) {
            setValue("privateSectorIndustry", "");
            setValue("publicSectorIndustry", "");
        }
    }, [watchSector])

    return (
        <>
            <Card className="mt-6 w-[90%] bg-transparent border-white border">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardBody>
                        <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-5">
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>First Name
                                </Typography>
                                <Input
                                    {...register("firstName", { required: "First name is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                    color="white"
                                    label="Your First Name"
                                    type="text"
                                    autoComplete="none"
                                    error={errors.firstName}
                                />
                                {errors.firstName && <span className="text-red-400 text-sm">{errors.firstName.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Last Name
                                </Typography>
                                <Input
                                    {...register("lastName", { required: "Last name is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                    color="white"
                                    label="Your Last Name"
                                    type="text"
                                    autoComplete="none"
                                    error={errors.lastName}
                                />
                                {errors.lastName && <span className="text-red-400 text-sm">{errors.lastName.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Company
                                </Typography>
                                <Input
                                    {...register("company", { required: "Company is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                    color="white"
                                    label="Your Company"
                                    type="text"
                                    autoComplete="none"
                                    error={errors.company}
                                />
                                {errors.company && <span className="text-red-400 text-sm">{errors.company.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Email
                                </Typography>
                                <Input
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Invalid email address"
                                        },
                                        validate: async (value) => {
                                            const emailExists = await verifyEmail(value);
                                            return !emailExists || "Email already exists";
                                        }
                                    })}
                                    className={`${errors.email && "text-red-500"} font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm`}
                                    color="white"
                                    label="Your Email"
                                    type="text"
                                    error={errors.email}
                                />
                                {errors.email && <span className="text-red-400 text-sm">{errors.email.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Job Title
                                </Typography>
                                <Input
                                    {...register("jobTitle", { required: "Job Title is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                    color="white"
                                    label="Your Job Title"
                                    type="text"
                                    autoComplete="none"
                                    error={errors.jobTitle}
                                />
                                {errors.jobTitle && <span className="text-red-400 text-sm">{errors.jobTitle.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Phone Number
                                </Typography>
                                <div className="flex items-center">
                                    <div className={`${errors.contact ? "border-red-500 text-red-500" : "border-white text-white"} bg-transparent border me-1 text-sm px-3 py-2.5 rounded-lg font-normal`}>+62</div>
                                    <Input
                                        {...register("contact", { required: "Phone Number is required" })} className="border-white font-normal w-full border placeholder:text-sm text-sm appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                        color="white"
                                        label="Your Mobile Number"
                                        type="number"
                                        autoComplete="none"
                                        error={errors.contact}
                                    />
                                </div>
                                {errors.contact && <span className="text-red-400 text-sm">{errors.contact.message}</span>}
                            </div>
                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Job Function
                                </Typography>
                                <Controller
                                    name="jobFunction"
                                    control={control}
                                    rules={{ required: 'Job Function is required' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            className={
                                                errors.jobFunction ?
                                                    "border-b-red-400 border-l-red-400 border-r-red-400"
                                                    :
                                                    "border-b-white border-l-white border-r-white text-white"
                                            }
                                            labelProps={{ className: errors.jobFunction ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                            value={value}
                                            onChange={onChange}
                                            label="Select Job Function"
                                            error={errors.jobFunction}
                                        >
                                            {
                                                jobFunctions.map((jobFunction, index) => {
                                                    return (
                                                        <Option key={index} value={jobFunction.value}>{jobFunction.label}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                    defaultValue=""
                                />
                                {errors.jobFunction && <span className="text-red-400 text-sm">{errors.jobFunction.message}</span>}
                            </div>

                            {
                                watchJobFunction === "IT / Technology" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Please tell us your job function
                                    </Typography>
                                    <Controller
                                        name="jobFunctionIt"
                                        control={control}
                                        rules={{ required: 'Job Function is required' }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                className={
                                                    errors.jobFunctionIt ?
                                                        "border-b-red-400 border-l-red-400 border-r-red-400"
                                                        :
                                                        "border-b-white border-l-white border-r-white text-white"
                                                }
                                                labelProps={{ className: errors.jobFunctionIt ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                                value={value}
                                                onChange={onChange}
                                                label="Select Job Function"
                                                error={errors.jobFunctionIt}
                                            >
                                                {
                                                    jobFunctionsIt.map((jobFunction, index) => {
                                                        return (
                                                            <Option key={index} value={jobFunction.value}>{jobFunction.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                        defaultValue=""
                                    />
                                    {errors.jobFunctionIt && <span className="text-red-400 text-sm">{errors.jobFunctionIt.message}</span>}
                                </div>
                            }

                            {
                                watchJobFunction === "Others" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Please tell us your job function
                                    </Typography>
                                    <Input
                                        {...register("jobFunctionOther", { required: "Job Function is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                        color="white"
                                        label="Your Job Function"
                                        type="text"
                                        autoComplete="none"
                                        error={errors.jobFunctionOther}
                                    />
                                    {errors.jobFunctionOther && <span className="text-red-400 text-sm">{errors.jobFunctionOther.message}</span>}
                                </div>
                            }

                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Job Level
                                </Typography>
                                <Controller
                                    name="jobLevel"
                                    control={control}
                                    rules={{ required: 'Job Level is required' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            className={
                                                errors.jobLevel ?
                                                    "border-b-red-400 border-l-red-400 border-r-red-400"
                                                    :
                                                    "border-b-white border-l-white border-r-white text-white"
                                            }
                                            labelProps={{ className: errors.jobLevel ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                            value={value}
                                            onChange={onChange}
                                            label="Select Job Level"
                                            error={errors.jobLevel}
                                        >
                                            {
                                                jobLevels.map((jobLevel, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={jobLevel.value}
                                                        >
                                                            {jobLevel.label}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                    defaultValue=""
                                />
                                {errors.jobLevel && <span className="text-red-400 text-sm">{errors.jobLevel.message}</span>}
                            </div>

                            {
                                watchJobLevel === "Others" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Please tell us your job level
                                    </Typography>
                                    <Input
                                        {...register("jobLevelOther", { required: "Job Level is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                        color="white"
                                        label="Your Job Level"
                                        type="text"
                                        autoComplete="none"
                                        error={errors.jobLevelOther}
                                    />
                                    {errors.jobLevelOther && <span className="text-red-400 text-sm">{errors.jobLevelOther.message}</span>}
                                </div>
                            }

                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Sector
                                </Typography>
                                <Controller
                                    name="sector"
                                    control={control}
                                    rules={{ required: 'Sector is required' }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            className={
                                                errors.sector ?
                                                    "border-b-red-400 border-l-red-400 border-r-red-400"
                                                    :
                                                    "border-b-white border-l-white border-r-white text-white"
                                            }
                                            labelProps={{ className: errors.sector ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                            value={value}
                                            onChange={onChange}
                                            label="Choose Sector"
                                            error={errors.sector}
                                        >
                                            <Option value="Private Sector">Private Sector</Option>
                                            <Option value="Government and Statutory Boards">Government and Statutory Boards</Option>
                                        </Select>
                                    )}
                                    defaultValue=""
                                />
                                {errors.sector && <span className="text-red-400 text-sm">{errors.sector.message}</span>}
                            </div>

                            {
                                watchSector === "Private Sector" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Industry
                                    </Typography>
                                    <Controller
                                        name="privateSectorIndustry"
                                        control={control}
                                        rules={{ required: 'Industry is required' }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                className={
                                                    errors.privateSectorIndustry ?
                                                        "border-b-red-400 border-l-red-400 border-r-red-400"
                                                        :
                                                        "border-b-white border-l-white border-r-white text-white"
                                                }
                                                labelProps={{ className: errors.privateSectorIndustry ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                                value={value}
                                                onChange={onChange}
                                                label="Select Industry"
                                                error={errors.privateSectorIndustry}
                                            >
                                                {
                                                    privateSector.map((industry, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={industry.value}
                                                            >
                                                                {industry.label}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                        defaultValue=""
                                    />
                                    {errors.privateSectorIndustry && <span className="text-red-400 text-sm">{errors.privateSectorIndustry.message}</span>}
                                </div>
                            }

                            {
                                watchSector === "Government and Statutory Boards" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Industry
                                    </Typography>
                                    <Controller
                                        name="publicSectorIndustry"
                                        control={control}
                                        rules={{ required: 'Industry is required' }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                className={
                                                    errors.publicSectorIndustry ?
                                                        "border-b-red-400 border-l-red-400 border-r-red-400"
                                                        :
                                                        "border-b-white border-l-white border-r-white text-white"
                                                }
                                                labelProps={{ className: errors.publicSectorIndustry ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                                value={value}
                                                onChange={onChange}
                                                label="Select Industry"
                                                error={errors.publicSectorIndustry}
                                            >
                                                {
                                                    publicSector.map((industry, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={industry.value}
                                                            >
                                                                {industry.label}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                        defaultValue=""
                                    />
                                </div>
                            }

                            {
                                watchPrivateSectorIndustry === "Others" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Please tell us your industry
                                    </Typography>
                                    <Input
                                        {...register("privateSectorOther", { required: "Industry is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                        color="white"
                                        label="Your Industry"
                                        type="text"
                                        autoComplete="none"
                                        error={errors.privateSectorOther}
                                    />
                                    {errors.privateSectorOther && <span className="text-red-400 text-sm">{errors.privateSectorOther.message}</span>}
                                </div>
                            }

                            {
                                watchPublicSectorIndustry === "Others" &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Please tell us your industry
                                    </Typography>
                                    <Input
                                        {...register("publicSectorOther", { required: "Industry is required" })} className="font-normal w-full border p-2 rounded-lg placeholder:text-sm text-sm"
                                        color="white"
                                        label="Your Industry"
                                        type="text"
                                        autoComplete="none"
                                        error={errors.publicSectorOther}
                                    />
                                    {errors.publicSectorOther && <span className="text-red-400 text-sm">{errors.publicSectorOther.message}</span>}
                                </div>
                            }

                            <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Objectives for attending the conference
                                </Typography>
                                <CustomSelect control={control} />
                                {errors.objectives && <span className="text-red-400 text-sm">{errors.objectives.message}</span>}
                            </div>
                            {/* <div className="w-full">
                                <Typography variant="h6" color="white" className="mb-2">
                                    <span className="text-red-400 pe-1">*</span>Choose Type
                                </Typography>
                                <div className={`${errors.nonDelegates ? "border-red-500" : "border-white"} grid grid-cols-2 gap-3 border rounded-lg`}>
                                    <div className="flex items-center cursor-pointer hover:bg-white/30 transition-all duration-300 py-2 px-3">
                                        <input
                                            {...register("nonDelegates", { required: "Delegate Type is required" })}
                                            type="radio"
                                            value={false}
                                            id="nonDelegatesFalse"
                                        />
                                        <label className="text-white text-base ps-2 cursor-pointer w-full" htmlFor="nonDelegatesFalse">Delegates</label>
                                    </div>
                                    <div className="flex items-center cursor-pointer hover:bg-white/30 transition-all duration-300 py-2 px-3">
                                        <input
                                            {...register("nonDelegates", { required: "Delegate Type is required" })}
                                            type="radio"
                                            value={true}
                                            id="nonDelegatesTrue"
                                        />
                                        <label className="text-white text-base ps-2 cursor-pointer w-full" htmlFor="nonDelegatesTrue">Non Delegates</label>
                                    </div>
                                </div>
                                {errors.nonDelegates && <span className="text-red-400 text-sm">{errors.nonDelegates.message}</span>}
                            </div> */}

                            {/* {
                                showCategoryType &&
                                <div className="w-full">
                                    <Typography variant="h6" color="white" className="mb-2">
                                        <span className="text-red-400 pe-1">*</span>Category
                                    </Typography>
                                    <Controller
                                        name="batchCategory"
                                        control={control}
                                        rules={{ required: 'Category is required' }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                className={
                                                    errors.batchCategory ?
                                                        "border-b-red-400 border-l-red-400 border-r-red-400"
                                                        :
                                                        "border-b-white border-l-white border-r-white text-white"
                                                }
                                                labelProps={{ className: errors.batchCategory ? "text-red-400 after:border-red-400 before:border-red-400" : "text-white after:border-white before:border-white" }}
                                                value={value}
                                                onChange={onChange}
                                                label="Select Category"
                                                error={errors.batchCategory}
                                            >
                                                <Option value="Organiser">Organiser</Option>
                                                <Option value="Speaker">Speaker</Option>
                                                <Option value="Sponsor">Sponsor</Option>
                                            </Select>
                                        )}
                                        defaultValue=""
                                    />
                                    {errors.batchCategory && <span className="text-red-400 text-sm">{errors.batchCategory.message}</span>}
                                </div>
                            } */}
                        </div>

                        <div className="border-t border-white/30 w-full my-5"></div>

                        <div className="mb-3 flex flex-col">
                            <Checkbox
                                {...register("tos", { required: "You must check the condition above first" })}
                                ripple={false}
                                className="border-white bg-transparent transition-all hover:scale-105 hover:before:opacity-0"
                                color="purple"
                                label={
                                    <Typography color="white" className="flex font-sm">I confirm that the contact information provided is for the purpose of Business Communications. If I have provided a non-business domain email address (such as, but not limited to, gmail, hotmail, etc) I am doing so in the context of it being used for my business and not personal interactions with GovInsider.</Typography>
                                }
                            />
                            {errors.tos && <span className="text-red-400 text-sm">{errors.tos.message}</span>}
                        </div>

                        <div className="flex flex-col">
                            <Checkbox
                                {...register("privacyPolicy", { required: "You must check the condition above first" })}
                                ripple={false}
                                className="border-white bg-transparent transition-all hover:scale-105 hover:before:opacity-0"
                                color="purple"
                                label={
                                    <Typography color="white" className="flex flex-col md:flex-row font-sm">
                                        I confirm that I have read and agreed to the&nbsp;
                                        <Typography
                                            as="a"
                                            href="https://govinsider.asia/intl-en/privacy-policy/"
                                            target="_blank"
                                            color="blue"
                                            className="font-medium transition-colors hover:text-blue-700"
                                        >
                                            Privacy Policy
                                        </Typography>
                                    </Typography>
                                }
                            />
                            {errors.privacyPolicy && <span className="text-red-400 text-sm">{errors.privacyPolicy.message}</span>}
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="w-full md:w-1/2 mx-auto">
                            <Button className="bg-[#4882E6] w-full py-3 text-base flex justify-center" loading={isSubmitting} type="submit">{isSubmitting ? "Processing..." : "Register"}</Button>
                        </div>
                    </CardFooter>
                </form>
            </Card>

            <Dialog size="sm" open={successAddAttendee} handler={handleOpen}>
                <DialogBody>
                    <div className="flex justify-center mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="size-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </div>
                    <div className="text-green-500 text-xl md:text-3xl text-center">Register Success</div>
                </DialogBody>
                <DialogFooter className="justify-center">
                    <Button
                        type="button"
                        color="gray"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        CLOSE
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default Form;