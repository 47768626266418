export const jobFunctions = [
    {
        value: "Accounting / Finance / Procurement",
        label: "Accounting / Finance / Procurement"
    },
    {
        value: "Admin / Operations / Customer Service",
        label: "Admin / Operations / Customer Service"
    },
    {
        value: "Audit / Compliance / Legal",
        label: "Audit / Compliance / Legal"
    },
    {
        value: "HR / Training",
        label: "HR / Training"
    },
    {
        value: "IT / Technology",
        label: "IT / Technology"
    },
    {
        value: "Marketing / Communications",
        label: "Marketing / Communications"
    },
    {
        value: "Policy / Planning / Strategy",
        label: "Policy / Planning / Strategy"
    },
    {
        value: "Research",
        label: "Research"
    },
    {
        value: "Others",
        label: "Others"
    }
]

export const jobLevels = [
    { value: "Academia", label: "Academia" },
    { value: "C-level / President / Owner / Chairman / Permanent Secretary / Deputy Secretary", label: "C-level / President / Owner / Chairman / Permanent Secretary / Deputy Secretary" },
    { value: "Director / GM / Head of Department", label: "Director / GM / Head of Department" },
    { value: "Assistant Director / Deputy Director", label: "Assistant Director / Deputy Director" },
    { value: "Manager / Specialist", label: "Manager / Specialist" },
    { value: "Executive / Officer", label: "Executive / Officer" },
    { value: "Others", label: "Others" }
];

export const publicSector = [
    { value: "Agriculture / Forestry", label: "Agriculture / Forestry" },
    { value: "Architecture / Construction / Engineering", label: "Architecture / Construction / Engineering" },
    { value: "Association / Non-Profit Organisation", label: "Association / Non-Profit Organisation" },
    { value: "Banking, Finance & Insurance", label: "Banking, Finance & Insurance" },
    { value: "Business Services & Consultancy", label: "Business Services & Consultancy" },
    { value: "Communications Services", label: "Communications Services" },
    { value: "Consumer Businesses", label: "Consumer Businesses" },
    { value: "Defence / Military / Security", label: "Defence / Military / Security" },
    { value: "Energy / Utilities / Oil & Gas / Mining", label: "Energy / Utilities / Oil & Gas / Mining" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Media & Advertising", label: "Media & Advertising" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Transportation / Logistics", label: "Transportation / Logistics" },
    { value: "Travel / Hospitality / Entertainment", label: "Travel / Hospitality / Entertainment" },
    { value: "Others", label: "Others" }
];

export const privateSector = [
    { value: "Agriculture & Food", label: "Agriculture & Food" },
    { value: "Building, Construction, Infrastructure & Public Works", label: "Building, Construction, Infrastructure & Public Works" },
    { value: "Communications & Information", label: "Communications & Information" },
    { value: "Culture, Community, Sports & Youth", label: "Culture, Community, Sports & Youth" },
    { value: "Defence - Air, Land, Sea", label: "Defence - Air, Land, Sea" },
    { value: "Education", label: "Education" },
    { value: "Environment, Natural Resources, Energy, Sustainability", label: "Environment, Natural Resources, Energy, Sustainability" },
    { value: "Finance", label: "Finance" },
    { value: "Foreign Affairs", label: "Foreign Affairs" },
    { value: "Health", label: "Health" },
    { value: "Home Affairs, Police, Prisons, Intelligence", label: "Home Affairs, Police, Prisons, Intelligence" },
    { value: "National Development, Manpower, Labour", label: "National Development, Manpower, Labour" },
    { value: "Parliament Office", label: "Parliament Office" },
    { value: "Science, Technology & Digital Economy", label: "Science, Technology & Digital Economy" },
    { value: "Social & Family Development, Non-Profit", label: "Social & Family Development, Non-Profit" },
    { value: "Trade, Industry & Commerce", label: "Trade, Industry & Commerce" },
    { value: "Transport - Land, Sea, Air", label: "Transport - Land, Sea, Air" },
    { value: "Tourism", label: "Tourism" },
    { value: "Others", label: "Others" }
];

export const jobFunctionsIt = [
    { value: "Security / Governance", label: "Security / Governance" },
    { value: "Data / Analytics", label: "Data / Analytics" },
    { value: "Hardware Infrastructure", label: "Hardware Infrastructure" },
    { value: "Software Infrastructure", label: "Software Infrastructure" },
    { value: "Network Infrastructure", label: "Network Infrastructure" },
    { value: "IT Administration & Procurement", label: "IT Administration & Procurement" },
    { value: "Helpdesk", label: "Helpdesk" }
];

export const defaultValues = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    jobTitle: "",
    jobFunction: "",
    jobFunctionOther: "",
    jobFunctionIt: "",
    jobLevel: "",
    contact: "",
    sector: "",
    privateSectorIndustry: "",
    privateSectorOther: "",
    publicSectorIndustry: "",
    publicSectorOther: "",
    objectives: [],
    nonDelegates: "false",
    industry: "",
    tos: false,
    privacyPolicy: false,
};